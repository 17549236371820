import { Box } from '@/components/box';
import * as AccessibleIcon from '@/components/icons';
import { ExternalLink as ExternalIcon } from '@/components/icons';
import { styled } from '@/stitches.config';
import type { ExternalLink } from '@/types/sanity';
import { ArrowRight } from '@/components/icons';
import NextLink from 'next/link';
import { getExternalLinkHref } from '../utils/getExternalLinkHref';
import { getInternalLinkHref } from '../utils/getInternalLinkHref';

type ButtonProps = Pick<ExternalLink, 'link' | 'suffix' | 'isInline'> & {
  onClick?: () => void;
  type?: 'internal' | 'external';
  internalLink?: {
    type?: string;
    slug?: string;
  };
  children: React.ReactNode;
};

export function ButtonLink(props: ButtonProps) {
  const {
    onClick,
    isInline,
    children,
    internalLink,
    link,
    suffix = '',
    type,
  } = props;

  const getDuplicateInternalButtonContent = () => {
    return (
      <>
        <DuplicateContent>{children} </DuplicateContent>
        <DuplicateContent>
          <ArrowRight />
        </DuplicateContent>
      </>
    );
  };

  // We duplicate the content for our hover effect, so we can invert its colours
  const getDuplicateExternalButtonContent = () => {
    return (
      <>
        <DuplicateContent>{children}</DuplicateContent>
        <DuplicateContent>
          <ExternalIcon
            width={20}
            height={20}
            style={{
              stroke: 'currentcolor',
              strokeWidth: 0.5,
              margin: 3,
            }}
          />
        </DuplicateContent>
      </>
    );
  };

  if (type === 'internal') {
    if (!internalLink) {
      console.warn('You forgot to add an internalLink reference:', link);
      return null;
    }

    const href = getInternalLinkHref(internalLink, suffix);

    return (
      <NextLink href={href} passHref>
        <StyledButton
          as="a"
          isInline={isInline}
          type="internal"
          iconAlignment="right"
        >
          <Box
            as="span"
            css={{
              display: 'flex',
              gap: '$2',
              alignItems: 'center',
            }}
          >
            <Content>{children}</Content>
            <ArrowRight />

            <BackgroundBlockLeft aria-hidden="true" iconAlignment="right">
              {getDuplicateInternalButtonContent()}
            </BackgroundBlockLeft>

            <BackgroundBlockRight aria-hidden="true" iconAlignment="right">
              {getDuplicateInternalButtonContent()}
            </BackgroundBlockRight>
          </Box>
        </StyledButton>
      </NextLink>
    );
  }

  if (type === 'external') {
    const href = getExternalLinkHref(link, suffix);

    return (
      <StyledButton
        as="a"
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        isInline={isInline}
        type="external"
        iconAlignment="right"
      >
        <Box
          as="span"
          css={{
            display: 'flex',
            gap: '$2',
            alignItems: 'center',
          }}
        >
          <Content>{children}</Content>
          <AccessibleIcon.Root label="Icon indicating link is from an external source and will open in new tab">
            <ExternalIcon
              width={20}
              height={20}
              style={{
                stroke: 'currentcolor',
                strokeWidth: 0.5,
                margin: 3,
              }}
            />
          </AccessibleIcon.Root>

          <BackgroundBlockLeft aria-hidden="true" iconAlignment="right">
            {getDuplicateExternalButtonContent()}
          </BackgroundBlockLeft>

          <BackgroundBlockRight aria-hidden="true" iconAlignment="right">
            {getDuplicateExternalButtonContent()}
          </BackgroundBlockRight>
        </Box>
      </StyledButton>
    );
  }

  return (
    <StyledButton
      isInline={isInline}
      onClick={onClick}
      css={{
        padding: '$3 $4 !important',
        '@bp3': {
          padding: '$3 $5 !important',
        },
      }}
    >
      <Content>{children}</Content>
      <BackgroundBlockLeft aria-hidden="true">
        {/* We duplicate the content for our hover effect, so we can invert its colours */}
        <DuplicateContent>{children}</DuplicateContent>
      </BackgroundBlockLeft>
      <BackgroundBlockRight aria-hidden="true">
        <DuplicateContent>{children}</DuplicateContent>
      </BackgroundBlockRight>
    </StyledButton>
  );
}

const RIGHT_ICON_SIZE = '44px';
const LEFT_TEXT_BLOCK_SIZE = `calc(100% - ${RIGHT_ICON_SIZE})`;
// The clip-path used on BackgroundBlockLeft and BackgroundBlockRight has a 1px gap between them
// So we make the Block that consumes the icon bigger by 1px, to overlap the two areas
const RIGHT_ICON_BLOCK_WIDTH = `calc(100% - ${RIGHT_ICON_SIZE} - 1px)`;

export const LEFT_ICON_SIZE = '40px';

export const buttonFocusStyle = {
  outlineStyle: 'dashed',
  outlineWidth: '1px',
  outlineColor: '$foreground',
  outlineOffset: '2px',
};

const Content = styled('span', {
  capSize: 2,
  position: 'relative',
  display: 'inline-block',
  color: 'currentColor',
});

const DuplicateContent = styled('span', {
  capSize: 2,
  position: 'relative',
  display: 'inline-block',
  color: '$background',

  '& + span': {
    display: 'flex',
    alignItems: 'center',
  },

  '& + span > svg': {
    color: '$background',
  },
});

/**
 *  Clip-path values - An Explanation
 *  When the individual background blocks fill up the background of StyledButtons, there's always at least 1px of gap between them and the button border.
 *  It makes the background peek through, which we do not want. So we expand the size of the polygons by 1px wherever it is applicable to cover up the gaps.
 */

export const BackgroundBlockLeft = styled('div', {
  position: 'absolute',
  display: 'inline-flex',
  backgroundColor: 'currentColor',
  alignItems: 'center',
  padding: '$2 $3 $2 $4',
  fontSize: '$2',
  inset: 0,

  clipPath:
    'polygon(-1px 100%, 50% 100%, 50% calc(100% + 1px), -1px calc(100% + 1px))', // rectangle with no height

  transition: 'clip-path 320ms ease-out',

  '@bp3': {
    padding: '$2 $4 $2 $5',
  },

  variants: {
    iconAlignment: {
      right: {
        padding: '$2 $3 $2 $4',
        display: 'flex',
        gap: '$2',
        clipPath: `polygon(0 100%, ${LEFT_TEXT_BLOCK_SIZE} 100%, ${LEFT_TEXT_BLOCK_SIZE} calc(100% + 1px), 0 calc(100% + 1px))`, // rectangle with no height
      },

      // used in the FullScreenVimeo component only
      // the Duplicate content used in this scenario is defined in FullScreenVimeo component
      left: {
        padding: '0',

        clipPath: `polygon(0% 100%, ${LEFT_ICON_SIZE} 100%, ${LEFT_ICON_SIZE} calc(100% + 1px), 0% calc(100% + 1px))`, // rectangle with no height
      },
    },
  },
});

export const BackgroundBlockRight = styled('div', {
  position: 'absolute',
  display: 'inline-flex',
  backgroundColor: 'currentColor',
  alignItems: 'center',
  padding: '$2 $3 $2 $4',

  fontSize: '$2',
  inset: 0,

  clipPath:
    'polygon(calc(50% - 1px) 100%, calc(100% + 1px) 100%, calc(100% + 1px) calc(100% + 1px), calc(50% - 1px) calc(100% + 1px))', // rectangle with no height

  transition: 'clip-path 320ms ease-out',
  transitionDelay: '100ms',

  '@bp3': {
    padding: '$2 $4 $2 $5',
  },

  variants: {
    iconAlignment: {
      right: {
        padding: '$2 $3 $2 $4',
        display: 'flex',
        gap: '$2',
        clipPath: `polygon(${RIGHT_ICON_BLOCK_WIDTH} 100%, 100% 100%, 100% calc(100% + 1px), ${RIGHT_ICON_BLOCK_WIDTH} calc(100% + 1px))`, // rectangle with no height
      },

      // used in the FullScreenVimeo component only
      // the Duplicate content used in this scenario is defined in FullScreenVimeo component
      left: {
        padding: '0',

        clipPath: `polygon(calc(${LEFT_ICON_SIZE} - 1px) calc(100% + 1px), 100% calc(100% + 1px), 100% 100%, calc(${LEFT_ICON_SIZE} - 1px) calc(100% + 1px))`, // rectangle with no height
        transitionDelay: '120ms',
      },
    },
  },
});

export const DEFAULT_LEFT_BLOCK_HOVER_STATE = {
  [`${BackgroundBlockLeft}`]: {
    clipPath:
      'polygon(-1px -1px, 50% -1px, 50% calc(100% + 1px), -1px calc(100% + 1px))', // rectangle with full height
  },
};

export const DEFAULT_RIGHT_BLOCK_HOVER_STATE = {
  [`${BackgroundBlockRight}`]: {
    clipPath:
      'polygon(calc(50% - 1px) -1px, calc(100% + 1px) -1px, calc(100% + 1px) calc(100% + 1px), calc(50% - 1px) calc(100% + 1px))', // rectangle with full height
  },
};

const LEFT_BLOCK_WITH_RIGHT_ICON_HOVER_STATE = {
  [`${BackgroundBlockLeft}`]: {
    clipPath: `polygon(-1px -1px, ${LEFT_TEXT_BLOCK_SIZE} -1px, ${LEFT_TEXT_BLOCK_SIZE} calc(100% + 1px), -1px calc(100% + 1px))`, // rectangle with full height
  },
};

const RIGHT_BLOCK_WITH_ICON_HOVER_STATE = {
  [`${BackgroundBlockRight}`]: {
    clipPath: `polygon(${RIGHT_ICON_BLOCK_WIDTH} -1px, calc(100% + 1px) -1px, calc(100% + 1px) calc(100% + 1px), ${RIGHT_ICON_BLOCK_WIDTH} calc(100% + 1px))`, // rectangle with full height
  },
};

export const StyledButton = styled('button', {
  boxSizing: 'border-box',
  alignItems: 'center',
  display: 'inline-flex',
  userSelect: 'none',
  border: '1px solid currentColor !important',
  fontSize: '$2',

  padding: '$2 $3 $2 $4 !important',
  position: 'relative',
  textDecoration: 'none',
  color: 'currentColor',
  marginBottom: '$7',
  backgroundColor: 'rgba(255, 255, 255, 0)',
  overflow: 'hidden',

  '@bp3': {
    padding: '$2 $4 $2 $5',
  },

  // focus state
  '&:focus-visible': {
    ...buttonFocusStyle,

    ...DEFAULT_LEFT_BLOCK_HOVER_STATE,
    ...DEFAULT_RIGHT_BLOCK_HOVER_STATE,
  },

  // hover state
  '&:hover': {
    ...DEFAULT_LEFT_BLOCK_HOVER_STATE,
    ...DEFAULT_RIGHT_BLOCK_HOVER_STATE,
  },

  // active state
  '&:active': {
    background: '$background',
    opacity: 0.8,
  },

  variants: {
    isInline: {
      true: {
        marginBottom: '$2',
      },
    },
    iconAlignment: {
      right: {
        '&:focus-visible': {
          ...buttonFocusStyle,

          ...LEFT_BLOCK_WITH_RIGHT_ICON_HOVER_STATE,
          ...RIGHT_BLOCK_WITH_ICON_HOVER_STATE,
        },

        '&:hover': {
          ...LEFT_BLOCK_WITH_RIGHT_ICON_HOVER_STATE,
          ...RIGHT_BLOCK_WITH_ICON_HOVER_STATE,
        },
      },
    },
  },
});

StyledButton.displayName = 'StyledButton';
